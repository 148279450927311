.header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.nav_list{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
}

.logo h5{
    font-size: 1.5rem;
    color: #c0e1ff;
    margin-top: 7px;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    margin-left: -30px;  /* Adjust this value as needed */
    padding-right: 40px; /* Compensate for the negative margin with padding */
}

.nav_item a{
    color: #fff;
    transition: .3s;
    font-weight: 400;
    cursor: pointer;
}

.nav_item a:hover{
    color: #c0e1ff;
}

.btn{
    background: #c0e1ff;
    color: rgb(22, 45, 79);
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 9rem;
    padding: 7px 25px;
}

.btn:hover{
    color: #fff
}

.header_shrink{
    background: #000;
    box-shadow: 2px 2px 4px 2px #c0e1ff;
}

@media (max-width: 768px) {
    .logo {
        margin-left: 0; /* Reset the negative margin for smaller screens */
    }

    .nav_list {
        column-gap: 0.8rem; /* Reduce the gap between nav items */
    }
}

/* ... [Existing CSS] ... */

/* Media query for smaller screens */
@media (max-width: 600px) {
    .logo {
        margin-left: 0; 
    }

    .nav_list {
        column-gap: 0.7rem; 
    }

    .nav_item a {
        font-size: 1rem;
    }

    .nav_menu {
        margin-top: 10px; 
    }
}


@media (max-width: 480px) {
    .logo h5 {
        margin-left: 0px;
    }

    .nav_list {
        column-gap: 0.5rem; 
    }

    .nav_item a {
        font-size: 0.8rem; 
    }

    .nav_menu {
        margin-top: 8px; 
    }
}