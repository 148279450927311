*{
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body{
  background: rgb(47, 89, 135);
}

html{
  scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6{
  color: #c0e1ff;
  font-family: -apple-system,BlinkMacSystemFont, "Segoe UI"
}

h1,h2{
  font-size: 2rem;
  font-weight: 500;
}

p{
  color: #fff;
  opacity: 80%;
  font-size: 1rem;
  font-family: -apple-system,BlinkMacSystemFont, "Segoe UI";
  font-weight: 400;
}


ul{
  list-style: none;
  margin: 0
}

a{
  color:unset;
  font-family: -apple-system,BlinkMacSystemFont, "Segoe UI"
}

a:hover{
  color:unset;
}

section, footer{
  padding: 60px 0px;
}

::-webkit-scrollbar{
  width: 10px;
  background: rgb(21, 52, 85);
}

::-webkit-scrollbar-thumb{
  border-radius: 2em;
  background: rgb(149, 188, 230);
}