.contact-full-width {
    width: 100%;
    margin-top: 50px;
    background: #132347; /* Dark background for contrast */
    padding: 20px 0; /* Top and bottom padding */
    color: #fff; /* White text color */
}

.contact_content_wrapper {
    display: flex;
    justify-content: space-around; /* Evenly space the columns */
    margin: 0 auto; /* Center align the row */
}

.contact-item {
    flex: 1; /* Each column takes equal width */
}

.contact_icon {
    color: #007bff; /* Blue icons */
    margin-bottom: 15px;
}

.contact_text h5 {
    margin: 0; /* Remove margin to tighten spacing */
}

.contact_link {
    color: #fff; /* White link color */
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact_link:hover .contact_icon {
    color: #0294f2; /* Lighter blue on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact_content_wrapper {
        flex-direction: column; /* Stack the links on smaller screens */
    }

    .contact-item {
        margin-bottom: 20px; /* Add some space between items */
    }
}
