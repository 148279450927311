#projects .purple {
    color: rgb(197, 184, 255);
}

#projects .blue {
    color: rgb(156, 205, 248);
}

#projects .single_project {
    background: #132347;
    padding: 20px;
    border-radius: 5px;
    border-bottom: 1px solid rgb(197, 184, 255);
    transition: 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 350px; /* Set a minimum height */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

#projects .single_project:hover {
    transform: translateY(-8px);
}

#projects .single_project h3 {
    font-size: 1.1rem; /* Adjusted to be consistent */
    margin-top: 15px;
    margin-bottom: 0.5rem; /* Reduced bottom margin for consistency */
}

#projects .single_project p {
    font-weight: 300;
    line-height: 30px;
    font-size: 0.9rem; /* Smaller font size for descriptions */
    flex-grow: 1; /* Allows paragraph to take available space */
}

#projects .job-description {
    font-size: 0.8rem; /* Smaller font size for technology list */
}

#projects .projects_top h2 {
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
}

@media only screen and (max-width: 768px) {
    .single_project {
        min-height: 300px; /* Smaller height for mobile devices */
    }

    .single_project h3 {
        font-size: 1rem; /* Adjusted for smaller screens */
    }

    .single_project p, .job-description {
        font-size: 0.8rem; /* Adjusted for smaller screens */
    }
}
