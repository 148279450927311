.awards_content_wrapper {
    background: #132347;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    margin-left: 10px;
    min-height: 200px;
    width: 80%;
    cursor: default;
}

.awards_content_wrapper:hover {
    transform: translateY(-8px);
}

.stylish-underline {
    display: inline-block;
    position: relative;
}

.stylish-underline::after {
    content: '';
    height: 3px;
    width: 60px;
    background-color: #f3bd84;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.awards-tabs-container {
    flex: 1;
    background: #132347;
    color: white;
    font-weight: 300px;
}

.awards-details-container {
    flex: 2;
    padding-left: 20px;
    font-size: 16px;
}

.MuiTab-root {
    color: #FFFFFF;
    font-weight: 300;
    /* background-color: purple; */
    transition: all 0.3s ease;
    border-radius: 5px;
    margin-bottom: 5px;
    opacity: 1;
    margin-top: -2px;
}

.MuiTabs-indicator {
    display: none;
}

.awards-container .Mui-selected {
    background: linear-gradient(to right, #0ec6c6, #0066cc);
    font-weight: 500;
}

.job-description {
    list-style-type: none;
    padding-left: 20px;
}

.job-description li:before {
    content: '\2022';
    color: #f3a847;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.job-title, .job-duration, .job-description li {
    color: #FFFFFF;
}