.experience_content_wrapper {
    background: #132347;
    padding: 30px;
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease;
    width: 80%; /* Reduce the width to make it less stretched */
    margin-left: 10px;
    cursor: default;
}

.experience_content_wrapper:hover {
    transform: translateY(-8px);
}

.stylish-underline {
    display: inline-block;
    position: relative;
}

.stylish-underline::after {
    content: '';
    height: 3px;
    width: 60px;
    background-color: #f3bd84;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.tabs-container {
    flex: 1;
    background: #132347;
    color: white;
}

.details-container {
    flex: 2;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 300;
}

.MuiTab-root {
    color: #FFFFFF;
    background-color: purple;
    transition: all 0.3s ease;
    border-radius: 5px;
    margin-bottom: 5px;
    opacity: 1;
}

.MuiTabs-indicator {
    display: none;
}


.Mui-selected {
    background: linear-gradient(to right, #7e3ca0, #0066cc);
    font-weight: 500;
}

.space{
    margin-bottom: 15px;
}

.job-description {
    list-style-type: none;
    padding-left: 20px;
    font-weight: 400;
}

.job-description li:before {
    content: '\2022';
    color: #f3a847;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.job-title, .job-duration, .job-description li {
    color: #FFFFFF;
}

.job-title, .job-duration{
    font-weight: 600 !important;
    color: #ffffff;
}