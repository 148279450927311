.about_content_wrapper {
    background: #132347;
    padding: 30px;
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease;
    width: 80%; /* Reduce the width to make it less stretched */
    margin-left: 10px;
}

.about_content_wrapper:hover {
    transform: translateY(-8px);
}

.about_content h2 {
    margin-bottom: 20px;
    color: #e0e0e0;
    text-align: left; /* Ensure the text "About Me" is left-aligned */
}

.about_content p {
    line-height: 1.6;
    font-weight: 350;
    color: #ffffff;
}

.stylish-underline {
    display: inline-block;
    position: relative;
}

.stylish-underline::after {
    content: '';
    height: 3px;
    width: 60px;
    background-color: #f3bd84;
    position: absolute;
    bottom: -5px;
    left: 50%; /* Center the underline beneath "About Me" */
    transform: translateX(-50%);
}
