
.white{
    color: #ffffff;
    opacity: 0.83;
}

.hero_title{
    color: #c5ceff;
}

.hero_title{
    font-weight: 600;
}

.hero_section{
    padding-top: 150px;
}

p{
    font-size: 1rem;
}

.orange{
    color: #b6dcff;;
}

.smaller{
    font-size: 1.3rem;
}

.big{
    font-size: 3.5rem !important;
}

.padleft{
    margin-left: 8px;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 2.5s forwards;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.hero-content p{
    line-height: 30px;
    font-weight: 300;
}
